// @ts-ignore to suppress phpstorm error
import $ from "jquery";
import Masonry from 'masonry-layout';
import SimpleLightbox from "simplelightbox";

let currentLightbox = null;

let masonry = new Masonry('.project-grid', {
    itemSelector: '.grid-item',
    percentPosition: true,
    gutter: '.gutter-sizer',
    columnWidth: '.grid-sizer'
});

const navBtn = document.querySelector('#navbutton');
const closeBtn = document.querySelector('#closebutton');
const navElement = document.querySelector('#sidebar');

function closeNavbar()
{
    navElement.classList.remove('open');
    document.body.classList.remove('no-scroll-mobile');
}

navBtn.addEventListener('click', () => {
    let isOpen = navElement.classList.contains('open');
    navElement.classList.toggle('open', !isOpen);
    document.body.classList.toggle('no-scroll-mobile', !isOpen);
});

closeBtn.addEventListener('click', () => {
    closeNavbar();
});

window.addEventListener("keydown", function(event) {
    if (event.key == "Escape")
    {
        closeNavbar();
    }
});

function filterProjects(key: string) {
    $('.project-grid').children('.grid-item').each(function()
    {
        if(key.toLowerCase() == "überall" ||
            this.getAttribute("data-category").toLowerCase() == key.toLowerCase())
        {
            $(this).show(50, function()
            {
                masonry.layout();
            });
        }
        else
        {
            $(this).hide(50, function()
            {
                masonry.layout();
            });
        }
    })
}

$('.category button').on('click', function() {
    closeNavbar();
    $('.project-grid').show(1);
    $('.project').hide(1);
    $('.active').removeClass('active');
    $(this).addClass('active');
    window.scrollTo(0, 0);
    filterProjects(this.innerText);
})

$('.grid-item').on('click', function() {
    closeNavbar();
    currentLightbox?.destroy();
    $('.project-grid').hide(1);
    let projectId = this.getAttribute("data-project-id");
    $(`#project-${projectId} .images img`).each(function()
    {
       let url = $(this).attr("data-url");
       $(this).attr("src", url)
    });
    currentLightbox = new SimpleLightbox(`#project-${projectId} a`, {"fileExt" : false, "animationSpeed": 50, "fadeSpeed": 100});
    window.scrollTo(0, 0);  // scroll position should be the top of the page when a project is opened
    $(`#project-${projectId}`).show(1);
})